import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "LoginID",
      description: "Full Stack Developer",
      dates: "Dec 2021 - Present"
    },
    {
      title: "Appers Lab",
      description: "Frontend Developer",
      dates: "Jun 2021 - Aug 2021"
    },
    {
      title: "Madkey Solutions Inc.",
      description: "Software Engineer",
      dates: "Jan 2020 - Sep 2020"
    }
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Experience</h2>
                  <p>
                    Since childhood, I was interested in computers - I loved puzzles and when I learned about the computer I wanted to know how everything works. After school, I started my engineering undergrad at SFU. My analytical mind and love for solving puzzles led me to the career path of Software Engineering. My experience in the internship after my undergrad further emphasized my interest to pursue in this area.
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.svg";
import linkedin from "../assets/img/linkedin.svg";
import github from "../assets/img/github.svg";
import email from "../assets/img/email.png";
import { HashLink } from "react-router-hash-link";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <HashLink to="#home">
              <img src={logo} alt="Logo" />
            </HashLink>
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/in/navjot-singh-chahal-8a0014137">
                <img src={linkedin} alt="linkedin" />
              </a>
              <a href="https://github.com/navjot-chahal">
                <img src={github} alt="github" />
              </a>
              <a href="mailto:navjot.chahal19@gmail.com">
                <img src={email} alt="email" />
              </a>
            </div>
            <p>Copyright © 2022. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

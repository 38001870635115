import kubernetes from "../assets/img/kubernetes.png";
import aws from "../assets/img/aws.png";
import docker from "../assets/img/docker.webp";
import mongo from "../assets/img/mongo.png";
import gitlab from "../assets/img/gitlab.png";
import golang from "../assets/img/golang.png";
import jest from "../assets/img/jest.png";
import node from "../assets/img/node.png";
import postgres from "../assets/img/postgres.png";
import python from "../assets/img/python.png";
import react from "../assets/img/react.png";
import postman from "../assets/img/postman.webp";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const skills = [
  {
    src: golang,
    name: "GoLang",
  },
  {
    src: node,
    name: "Node.js + Express",
  },
  {
    src: kubernetes,
    name: "Kubernetes / EKS",
  },
  {
    src: docker,
    name: "Docker",
  },
  {
    src: aws,
    name: "AWS",
  },
  {
    src: python,
    name: "Python/Bash Scripting",
  },
  {
    src: mongo,
    name: "MongoDB",
  },
  {
    src: postgres,
    name: "PostgreSQL",
  },
  {
    src: react,
    name: "React",
  },
  {
    src: jest,
    name: "Jest",
  },
  {
    src: postman,
    name: "Postman",
  },
  {
    src: gitlab,
    name: "GitLab CI/CD",
  },
];

export const Skills = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 9999, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                slidesToSlide={2}
              >
                {skills.map((skill) => (
                  <div className="item" key={skill.src}>
                    <img src={skill.src} alt={skill.name} />
                    <h5>{skill.name}</h5>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
